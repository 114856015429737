import ColumnSelector from "@components/Commons/ColumnSelector/ColumnSelector";
import useSelectedColumns from "@lib/hooks/use-selected-columns";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ui/table";
import { CardContainer } from "@components/Cards/CardContainer";

import React, { useEffect, useMemo, useState } from "react";
import { MarketingChannelOverviewInterface } from "../../../interface/MarketingChannelOverviewInterface";
import { convertSelectedColumns } from "@lib/util-functions/table/columns";
import {
  attributionModelAtom,
  historicalChartMetricAtom,
  newVsReturningAtom,
  selectedMarketingChannelsAtom,
} from "atoms";
import { useAtom } from "jotai";
import InfluencerOutdatedTooltip from "./InfluencerOutdatedTooltip";
import MetricNameWithTooltip from "@components/MetricNameWithTooltip/MetricNameWithTooltip";
import { mapChannelToColor } from "@api/services/analytics";
import { useDebounce } from "@lib/hooks";
import OrganicTooltip from "./OrganicTooltip";
import DirectTooltip from "./DirectTooltip";
import {
  Area,
  AreaChart,
  ComposedChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import SpendTableDisplay from "../../NumberDisplays/SpendTableDisplay";
import RevenueTableDisplay from "../../NumberDisplays/RevenueTableDisplay";
import OrdersTableDisplay from "../../NumberDisplays/OrdersTableDisplay";
import RoasTableDisplay from "../../NumberDisplays/RoasTableDisplay";
import CacTableDisplay from "../../NumberDisplays/CacTableDisplay";
import CpoTableDisplay from "../../NumberDisplays/CpoTableDisplay";
import AovTableDisplay from "../../NumberDisplays/AovTableDisplay";
import ConversionRateTableDisplay from "../../NumberDisplays/ConversionRateTableDisplay";
import AtcTableDisplay from "../../NumberDisplays/AtcTableDisplay";
import ProductviewTableDisplay from "../../NumberDisplays/ProductviewTableDisplay";
import PageviewTableDisplay from "../../NumberDisplays/PageviewTableDisplay";
import NewCustomerRateTableDisplay from "../../NumberDisplays/NewCustomerRateTableDisplay";
import NewVisitorRateTableDisplay from "../../NumberDisplays/NewVisitorRateTableDisplay";
import dayjs from "dayjs";
import {
  AVAILABLE_MARKETING_CHANNEL_OPTIONS,
  METRICS_LABELS,
} from "@/constants/constants";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectValue,
  SelectTrigger,
} from "@components/ui/select";
import { availableHistoricalChartMetrics } from "@lib/hooks/constants";
import HistoricalChartDisplay from "@components/NumberDisplays/HistoricalChartDisplay";
import HistoricalChartHeaderSelect from "@components/NumberDisplays/HistoricalChartHeaderSelect";

type MarketingChannelOverviewTableProps = {
  data: MarketingChannelOverviewInterface[];
  comparedData?: MarketingChannelOverviewInterface[];
  loading?: boolean;
  progress?: number;
  isInfluencerData?: boolean;
  title?: string;
};

const columnHelper = createColumnHelper<MarketingChannelOverviewInterface>();

function MarketingChannelOverviewTable(
  props: MarketingChannelOverviewTableProps
) {
  const { data, loading, progress, isInfluencerData, title, comparedData } =
    props;

  const compared = Boolean((comparedData ?? [])?.length > 0);
  const [attributionModel] = useAtom(attributionModelAtom);
  const { selectedColumns } = useSelectedColumns({
    table: isInfluencerData
      ? "influencerChannelOverview"
      : "marketingChannelOverview",
  });
  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
    convertSelectedColumns(selectedColumns)
  );
  const [selectedMarketingChannels] = useAtom(selectedMarketingChannelsAtom);

  const tableData = useMemo(() => {
    if (compared) {
      const newData = [];
      for (const d of data) {
        const compared = comparedData?.find((c) => c.channel === d.channel);
        if (compared) {
          newData.push({
            ...d,
            compared: {
              ...compared,
            },
          });
        }
      }
      return newData;
    } else return data;
  }, [compared, data, comparedData]);

  useEffect(() => {
    setVisibleColumns(convertSelectedColumns(selectedColumns));
  }, [selectedColumns]);

  const pinnedColumns = { left: ["channel"] };
  const debouncedAttributionMode = useDebounce(attributionModel, 2000);
  const allChannelsSelected =
    selectedMarketingChannels.length ===
    AVAILABLE_MARKETING_CHANNEL_OPTIONS.length;
  const columns = useMemo(
    () => [
      columnHelper.accessor("channel", {
        enableResizing: false,
        enableSorting: false,
        header: "Channel",
        size: 150,
        cell: ({ row, getValue, cell }) => (
          <div className="w-full flex items-center relative">
            <div
              className={`rounded-lg p-2 block w-full font-semibold text-center text-white `}
              style={{ backgroundColor: mapChannelToColor(getValue()) }}
            >
              {getValue()}
            </div>
            {getValue() === "Influencer (old)" ? (
              <div className="absolute -right-6 top-2">
                <InfluencerOutdatedTooltip />
              </div>
            ) : null}
            {getValue() === "Direct" ? (
              <div className="absolute -right-6 top-2">
                <DirectTooltip />
              </div>
            ) : null}
            {getValue() === "Organic" ? (
              <div className="absolute -right-6 top-2">
                <OrganicTooltip />
              </div>
            ) : null}
          </div>
        ),
        footer: ({ table }) => {
          const pagination = table.getState().pagination;
          const rows: any[] = table
            .getRowModel()
            .rows.slice(
              pagination.pageIndex * pagination.pageSize,
              (pagination.pageIndex + 1) * pagination.pageSize
            );
          return (
            <div className="font-semibold flex items-center gap-1">
              <span> Total ({rows?.length ?? 0})</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("historical", {
        enableHiding: true,
        enableResizing: false,
        id: "historical",
        size: 280,
        enableSorting: false,
        cell: (info) => (
          <HistoricalChartDisplay info={info} compared={compared} />
        ),
        header: () => <HistoricalChartHeaderSelect />,
      }),
      columnHelper.accessor("spend", {
        enableHiding: true,
        enableResizing: false,
        sortDescFirst: true,
        size: compared ? 210 : 150,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        cell: (info) => (
          <SpendTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
          />
        ),
        footer: (info) => (
          <SpendTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),

      columnHelper.accessor("purchaseCount", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 190 : 150,
        cell: (info) => (
          <OrdersTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            attributionMode={debouncedAttributionMode}
          />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <OrdersTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            attributionMode={debouncedAttributionMode}
            calculateTotals
          />
        ),
      }),

      columnHelper.accessor("purchaseAmount", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 210 : 150,
        cell: (info) => (
          <RevenueTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
          />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <RevenueTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),

      columnHelper.accessor("roas", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 150,
        cell: (info) => <RoasTableDisplay info={info} compared={compared} />,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <RoasTableDisplay info={info} compared={compared} calculateTotals />
        ),
      }),

      columnHelper.accessor("cac", {
        enableHiding: true,
        size: compared ? 180 : 150,
        enableResizing: false,
        cell: (info) => <CacTableDisplay info={info} compared={compared} />,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <CacTableDisplay info={info} compared={compared} calculateTotals />
        ),
      }),
      columnHelper.accessor("newCustomerRate", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 180,
        cell: (info) => (
          <NewCustomerRateTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
          />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <NewCustomerRateTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("newVisitorRate", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 150,
        cell: (info) => (
          <NewVisitorRateTableDisplay info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <NewVisitorRateTableDisplay
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("aov", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 150,
        cell: (info) => <AovTableDisplay info={info} compared={compared} />,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <AovTableDisplay info={info} compared={compared} calculateTotals />
        ),
      }),
      columnHelper.accessor("cr", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 150,
        cell: (info) => (
          <ConversionRateTableDisplay info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <ConversionRateTableDisplay
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("cpo", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 180 : 150,
        cell: (info) => <CpoTableDisplay info={info} compared={compared} />,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <CpoTableDisplay info={info} compared={compared} calculateTotals />
        ),
      }),
      columnHelper.accessor("addtocart", {
        enableHiding: true,
        enableResizing: false,
        size: compared ? 200 : 150,
        cell: (info) => <AtcTableDisplay info={info} compared={compared} />,
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <AtcTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("productview", {
        enableSorting: true,
        enableHiding: true,
        enableResizing: false,
        size: compared ? 200 : 150,
        cell: (info) => (
          <ProductviewTableDisplay info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <ProductviewTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
      columnHelper.accessor("pageview", {
        enableSorting: true,
        enableHiding: true,
        enableResizing: false,
        size: compared ? 200 : 150,
        cell: (info) => (
          <PageviewTableDisplay info={info} compared={compared} />
        ),
        header: ({ header }) => (
          <MetricNameWithTooltip metric={header.id as any} />
        ),
        footer: (info) => (
          <PageviewTableDisplay<MarketingChannelOverviewInterface>
            info={info}
            compared={compared}
            calculateTotals
          />
        ),
      }),
    ],
    [compared, debouncedAttributionMode]
  );

  let emptyState;
  let loadingState;
  if (loading) {
    loadingState = (
      <div className="relative flex w-[calc(100vw-320px)]l justify-center items-center">
        <div className="loader mr-4"></div>
        <p className="text-foreground">
          {`${Math.min(progress ?? 0, 99)?.toFixed(0)}`} % processed...
        </p>
      </div>
    );
  } else if (!data || data.length === 0) {
    emptyState = (
      <div className="relative flex w-[calc(100vw-320px)] justify-center items-center">
        {progress ? (
          <p>No data found for selected time-range...</p>
        ) : (
          <p>No data has been loaded...</p>
        )}
      </div>
    );
  }

  return (
    <CardContainer>
      <div className="flex flex-col xl:flex-row xl:justify-between xl:items-center ">
        <div className="flex flex-col">
          <h3 className="h3">
            {title
              ? title
              : isInfluencerData
                ? "Influencer Channel Overview"
                : "Channel Overview"}
          </h3>
        </div>
        {isInfluencerData ? (
          <div className="mt-4 xl:mt-0">
            <ColumnSelector
              type="button"
              table={
                isInfluencerData
                  ? "influencerChannelOverview"
                  : "marketingChannelOverview"
              }
            />
          </div>
        ) : (
          <div className={`mt-4 xl:mt-0`}>
            <ColumnSelector type="button" table={"marketingChannelOverview"} />
          </div>
        )}
      </div>
      <div>
        <Table<MarketingChannelOverviewInterface>
          id="marketing-channel-overview-table"
          columns={columns}
          disableRowsPerPage={true}
          columnsOrder={selectedColumns}
          pinnedColumns={pinnedColumns}
          loadingState={loadingState}
          showFooter
          emptyState={emptyState}
          visibleColumns={visibleColumns}
          defaultSorting={[{ id: "spend", desc: true }]}
          data={tableData}
          enableResizing
          spacing={compared ? "wide" : "normal"}
        />
        {allChannelsSelected ? null : (
          <p className="-mt-6 text-sm">
            The totals displayed can differ from your store, as you have not
            included all{" "}
            <a
              href="https://knowledge.tracify.ai/en/articles/22107-how-do-included-multi-channel-touchpoints-influence-your-attribution-data"
              target="_blank"
              className="underline"
            >
              channel touchpoints
            </a>{" "}
            in the attribution.
          </p>
        )}
      </div>
    </CardContainer>
  );
}

export default MarketingChannelOverviewTable;
