import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import React from "react";

const OrganicTooltip = () => {
  return (
    <Tooltip>
      <TooltipTrigger>
        <QuestionMarkCircleIcon className="ml-1 h-5 w-5 text-gray-500" />
      </TooltipTrigger>
      <TooltipContent>
        <p className="w-96">
          In general, organic is defined as users visiting your site from
          another page without any paid push marketing efforts from your end
          (&quot;naturally earned&quot;).{" "}
          <a
            href="https://knowledge.tracify.ai/en/articles/92685-what-is-the-difference-between-organic-traffic-and-direct-traffic"
            rel="noreferrer"
            target="_blank"
            className="underline"
          >
            Read more
          </a>
        </p>
      </TooltipContent>
    </Tooltip>
  );
};

export default OrganicTooltip;
